.app-bar {
  background-color: #1d2127 !important;
  display: -webkit-inline-box !important;
}

.logoImg {
  height: 60px;
  margin-bottom: -7px;
  object-fit: fill;
}

.modal {
  position: absolute;
  width: 400px;
  background-color: #fff;
  top: 43%;
  left: 48%;
  transform: translate(-43%, -50%);
  box-shadow: 5px 5px 5px #333;
  border-radius: 5px;
}

.modal-title {
  font-size: 18px !important;
  /* padding: 15px;
  text-align: center; */
}

.grid {
  text-align: center;
  padding: 15px;
}

.text-field {
  width: 300px;
}

.btn-text-field {
  background-color: #3f51b5 !important;
  color: #fff !important;
}

.menu-button {
  color: #fff !important;
  position: relative;
  top: 8px;
  left: 5px;
}
