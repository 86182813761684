.modal {
  padding: 24px;
}

.modal-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.3125rem;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.16667em;
  margin-bottom: 15px !important;
}

.center {
  text-align: center;
}

.section-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  border-bottom: 1px solid #888;
}

.section-item {
  text-align: left;
  padding-left: 50px;
}

.section-value {
  font-weight: 600 !important;
}

.no-right {
  text-align: left;
}

.btn-top {
  margin-top: 10px !important;
}

.printer-btn {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
