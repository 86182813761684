.cube {
  width: 10px;
  height: 10px;
  position: absolute;
  -webkit-animation: cubemove 4s infinite ease-in-out;
  animation: cubemove 4s infinite;
}

@keyframes cubemove {
  35% {
    -webkit-transform: scale(0.005);
  }

  50% {
    -webkit-transform: scale(1.7);
  }

  65% {
    -webkit-transform: scale(0.005);
  }
}

/* Blue row */
.cube0 {
  background-color: #3696d6;
  left: 0px;
  top: 0px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  border-top-left-radius: 5px;
}

.cube1 {
  background-color: #3696d6;
  left: 14px;
  top: 0px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.1s;
}

.cube2 {
  background-color: #3696d6;
  left: 28px;
  top: 0px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.2s;
}

.cube3 {
  background-color: #3696d6;
  left: 42px;
  top: 0px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.3s;
}

.cube4 {
  background-color: #3696d6;
  left: 56px;
  top: 0px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.4s;
  border-top-right-radius: 5px;
}

/* Red row */
.cube5 {
  background-color: #f07452;
  left: 0px;
  top: 14px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.cube6 {
  background-color: #f07452;
  left: 14px;
  top: 14px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.1s;
}

.cube7 {
  background-color: #f07452;
  left: 28px;
  top: 14px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.2s;
}

.cube8 {
  background-color: #f07452;
  left: 42px;
  top: 14px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.3s;
}

.cube9 {
  background-color: #f07452;
  left: 56px;
  top: 14px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.4s;
}

/* Green row */
.cube10 {
  background-color: #369944;
  left: 0px;
  top: 28px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.cube11 {
  background-color: #369944;
  left: 14px;
  top: 28px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.1s;
}

.cube12 {
  background-color: #369944;
  left: 28px;
  top: 28px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.2s;
}

.cube13 {
  background-color: #369944;
  left: 42px;
  top: 28px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.3s;
}

.cube14 {
  background-color: #369944;
  left: 56px;
  top: 28px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.4s;
}

/* Yellow row */
.cube15 {
  background-color: #ebac30;
  left: 0px;
  top: 42px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  border-bottom-left-radius: 5px;
}

.cube16 {
  background-color: #ebac30;
  left: 14px;
  top: 42px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.1s;
}

.cube17 {
  background-color: #ebac30;
  left: 28px;
  top: 42px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.2s;
}

.cube18 {
  background-color: #ebac30;
  left: 42px;
  top: 42px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.3s;
}

.cube19 {
  background-color: #ebac30;
  left: 56px;
  top: 42px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.4s;
  border-bottom-right-radius: 5px;
}

.spinner {
  width: 67px;
  height: 52px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader-info {
  width: 200px;
  height: 12px;
  position: fixed;
  top: 87px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  opacity: 0.7;
}

.loader-progress {
  width: 200px;
  height: 5px;
  position: fixed;
  top: 120px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 5px;
  opacity: 0.7;
}

.loader-progress-value {
  max-width: 200px;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0.7;
}

.backdrop {
  position: fixed;
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
