.logoImg {
  height: 60px;
}

.list {
  margin-top: 60px !important;
  background-color: #fff;
  padding: 0 !important;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: contain;
}

.productDetails {
  float: right;
}

.productDiscount {
  position: relative;
  right: 15px;
}

.cartFooterDetails {
  float: right;
  position: relative;
}

.cartFooterDetailsLabel {
  font-size: 16px;
}

.cartFooterDetailsLabelTotal {
  font-size: 20px;
}

.cartDetails {
  float: right;
  position: relative;
  right: 30px;
}

.cartTotal {
  padding: 10px;
  font-size: 28px;
  font-weight: 600;
}

.paper {
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px -10px 20px -5px rgba(0, 0, 0, 0.75) !important;
}

.subPaper {
  padding: 10px;
}

.fixedItem {
  height: 130px;
}

.listItem {
  background-color: #fff;
}

.cart-icon-badge {
  position: absolute !important;
  right: 50px;
  top: 20px;
  z-index: 10001;
}

.cart-icon {
  position: absolute;
  top: 0px;
  right: 5px;
  color: #fff;
}

.modal-width {
  width: 380px;
}